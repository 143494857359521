import React from "react"

export default function HeroImage({ color, title }) {
  return (
    <div className="header-image-wrapper">
      <table>
        <tbody>
          <tr>
            <td align="center">
              <div style={{ position: "relative", maxWidth: "1300px" }}>
                <img
                  className="header-image"
                  src="/header.jpeg"
                  alt="Welcome Header"
                />
                <div
                  style={{ backgroundColor: `${color}` }}
                  className="sidebatch"
                >
                  <p>{title}</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
