import React from "react"

export default function Content({ bgColor, children }) {
  return (
    <div className="content wrapper">
      <div
        className="content container"
        style={{ backgroundColor: `${bgColor}` }}
      >
        {children}
      </div>
    </div>
  )
}
